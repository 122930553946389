/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { Link } from "@chakra-ui/react";
import { LoginRequest } from "@gruene-brise/data-access/api/generated";
import useAuth0 from "@gruene-brise/data-access/hooks/useAuth0";
import { useGraphql } from "@gruene-brise/data-access/hooks/useGraphql";
import useGraphqlError from "@gruene-brise/data-access/hooks/useGraphqlError";
import { LoginProps, LoginSchema } from "@gruene-brise/data-access/schema/LoginSchemes";
import { ReactComponent as ArrowRight } from "@gruene-brise/common-ui/assets/icons/arrow-right.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRouter } from "next/router";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import FormButton from "../form/FormButton";
import FormInput from "../form/FormInput";
import FormPassword from "../form/FormPassword";

const UserSignInMethod = ({ onRegister, onClose }: { onRegister?(): void; onClose?(): void }) => {
  const graphql = useGraphql();
  const router = useRouter();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { setUserAuthentication } = useAuth0("webshop");

  const loginMutation = useMutation((input: LoginRequest) => graphql.login({ input }), {});
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm<LoginProps>({
    defaultValues: {},
    resolver: yupResolver(LoginSchema(t)),
    mode: "all",
    reValidateMode: "onChange",
  });

  const { showError } = useGraphqlError();

  const onSubmit = async (e: LoginProps) => {
    try {
      setIsLoading(true);
      await loginMutation.mutateAsync({
        email: e.email!,
        password: e.password!,
      });

      await setUserAuthentication("webshop");
      const redirectTo = router.query.redirectTo as string;
      if (redirectTo) {
        router.push(redirectTo);
      } else {
        router.push("/");
      }

      onClose?.();

      setIsLoading(false);
    } catch (e) {
      showError(e);
      setIsLoading(false);
    }
  };

  return (
    <div className=' flex flex-col justify-center'>
      <FormInput
        label={t("Email Address")!}
        placeholder={t("Type your email address")!}
        errors={errors?.email?.message}
        register={register("email")}
        type={"email"}
      />

      <FormPassword
        label={t("Password")!}
        placeholder={t("Type your password")!}
        errors={errors?.password?.message}
        setValue={setValue}
        name='password'
        register={register("password")}
      />

      <div className='flex flex-row items-center relative -top-1 justify-end'>
        <Link href={"/forgotpassword"}>
          <span className=' text-fail text-[15px] ml-2 font-gellix font-[500]'>
            {t("Forgot Password?")}
          </span>
        </Link>
      </div>

      <div className='py-6 pb-4'>
        <FormButton
          title={
            <span className='flex gap-x-2.5 justify-center items-center'>
              {t("Login")!}
              <ArrowRight />
            </span>
          }
          onPress={handleSubmit(onSubmit)}
          isLoading={isLoading}
        />
      </div>

      <div className=''>
        <FormButton
          title={t("Create Account")!}
          outline
          className='text-primary-100 font-[600]'
          onPress={(e) => {
            e.preventDefault();
            if (onRegister) {
              onRegister();
            } else {
              router.push("/signup");
            }
          }}
        />
      </div>
    </div>
  );
};

export default UserSignInMethod;
